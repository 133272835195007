<template>
    <v-layout
        mt-5
        justify-center>
        <v-flex
            xs12
            sm10
            md8
            lg5
            pt-5
            mt-5>
            <v-card
                class="wrap-content gray-icons"
                light>
                <v-alert
                    :value="alertMessage"
                    :type="alertType"
                    transition="fade-transition"
                    class="mt-4 mx-4">
                    <span v-html="alertMessage" />
                </v-alert>
                <v-toolbar
                    dark
                    color="white"
                    class="first-line-toolbar">
                    <v-toolbar-title class="light-blue--text title-block">
                        Reset Password
                    </v-toolbar-title>
                </v-toolbar>
                <v-toolbar
                    v-if="enforcedReset"
                    style="background: inherit">
                    <p class="px-5">
                        To ensure better security it’s time to reset your password, please enter your email. Check your inbox for a link to complete the process.
                    </p>
                </v-toolbar>
                <v-card-text>
                    <v-container
                        fluid
                        grid-list-md>
                        <v-form
                            id="forgot-password-form"
                            v-model="valid"
                            class="text-xs-center"
                            @submit.prevent="submit">
                            <v-text-field
                                v-model="email"
                                label="E-mail"
                                :rules="emailRules"
                                required
                                class="py-4 px-5" />
                            <div class="form-button">
                                <v-btn
                                    flat
                                    large
                                    class="blue-btn"
                                    color="white"
                                    type="submit"
                                    form="forgot-password-form"
                                    :loading="loading"
                                    :disabled="!valid">
                                    Send Reset Link
                                </v-btn>
                            </div>
                        </v-form>
                    </v-container>
                </v-card-text>
            </v-card>
        </v-flex>
    </v-layout>
</template>

<script>
    import ApiRepository from '../../api-repository';

    export default {
        title: 'Forgot Password',
        data() {
            return {
                alertType: 'error',
                alertMessage: null,
                loading: false,
                valid: false,
                email: '',
                token: null,
                emailRules: [
                    (v) => !!v || 'E-mail is required',
                    (v) => /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/.test(v) || 'E-mail must be valid'
                ],
                enforcedReset: false,
            };
        },
        created() {
            this.enforcedReset = this.$route.params.enforcedReset === true;

            if (this.$route.params.email) {
                this.email = this.$route.params.email;
            }
        },
        methods: {
            showAlert(type, message) {
                this.alertType = type;
                this.alertMessage = message;
            },
            clearAlert() {
                this.alertMessage = null;
            },
            async submit() {

                try {
                    this.clearAlert();
                    this.loading = true;

                    const apiRepository = new ApiRepository();
                    await apiRepository.sendPasswordReset(this.email, this.enforcedReset);

                    this.showAlert('success', `Password reset link sent to ${this.email}!  Use the link in that email to reset your password.`);

                } catch (error) {
                    let message = `An error occurred. Please try again or
                                    <a href="mailto:support@buyerbridge.io">contact support</a>
                                    if you continue having issues.`;
                    if (error.response.status === 404) {
                        message = `An error occurred. Please confirm you entered the correct
                                    email associated with your account and try again or
                                    <a href="mailto:support@buyerbridge.io">contact support</a>
                                    if you continue having issues.`;
                    }
                    this.showAlert('error', message);
                } finally {
                    this.loading = false;
                }
            }
        }
    };
</script>
